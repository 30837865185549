import React, { useLayoutEffect, useRef, useState } from "react"

import PropTypes from "prop-types"

const Services = ({ className, thumbUrl, title, details }) => {
  const targetRef = useRef()
  const [dimensions, setDimensions] = useState({ height: 0 })
  // Add all classes to an array
  const addAllClasses = ["service_post"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        height: targetRef.current.offsetHeight,
      })
    }
  }, [])

  const selectClass = dimensions.height <= 50 ? "title" : "titleAlt"

  return (
    <div className={addAllClasses.join(" ")}>
      <div className="thumbnail">
        <img loading="lazy" src={thumbUrl} alt={`${title} thumbnail`} width="40" height="40" />
      </div>
      <div className="content">
        <div>
          <p className={selectClass} ref={targetRef}>
            {title}
          </p>
        </div>
        <ul className="details">
          <div dangerouslySetInnerHTML={{ __html: details }} />
        </ul>
      </div>
    </div>
  )
}

Services.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.string,
  title: PropTypes.string,
  details: PropTypes.string,
  link: PropTypes.element,
}

export default Services
